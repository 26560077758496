import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import styled from "styled-components"
import { MDXRenderer } from "gatsby-plugin-mdx"
import SEO from "../components/seo"

const PageContainer = styled.div`
    margin: 0 auto;
    padding: 0 20px;
    max-width: 1000px;
    li {
        margin-bottom: 0;
    }
`

export default function Template({
    data, // this prop will be injected by the GraphQL query below.
}) {
    const { mdx } = data 
    const { body, frontmatter } = mdx
    return (
        <>
            <SEO
                title={frontmatter.title}
            />
            <Layout>
                <PageContainer>
                    <MDXRenderer>
                        {body}
                    </MDXRenderer>
                </PageContainer>
            </Layout>
        </>
    )
}
export const pageQuery = graphql`
  query($slug: String!) {
    mdx(frontmatter: { slug: { eq: $slug } }) {
      body
      frontmatter {
        title
      }
    }
  }
`
